



















import { Component, Vue } from "vue-property-decorator";
import DataTable from "@/shared/components/data-table/DataTable.vue";
import DataTableBase from "@/shared/classes/components/data-table/data-table";
import DataTableHeader from "@/shared/classes/components/data-table/data-table-header";
import __ from "@/shared/helpers/__";
import EditAction from "@/shared/classes/components/data-table/default-actions/edit-action";
import Company from "@/shared/modules/company/company.model";
import { CompanyGetters } from "@/shared/store/company/company.getters";
import DeleteAction from "@/shared/classes/components/data-table/default-actions/delete-action";
import { GlobalActions } from "@/shared/store/global/global.actions";
import { SnackBarTypes } from "@/shared/helpers/snack-bar.helper";
import AreYouSureDialog from "@/shared/components/dialogs/AreYouSureDialog.vue";
import { CompanyRoutes } from "@/shared/router/company";
import { getNodeApiUrlByVersion } from "@/config";
import AssignedHseRoutinesModel from "@/shared/modules/assigned-hse-routines/assigned-hse-routines.model";
import AssignedHseRoutinesService from "@/shared/modules/assigned-hse-routines/assigned-hse-routines.service";
import SelectAction from "@/shared/classes/components/data-table/data-table-select-actions";
import SelectedRoutineModify from "@/company/components/hse-routines/SelectedRoutineModify.vue";
import { RoleLevel } from "@/shared/modules/role/role.model";
import roleCan from "@/shared/helpers/role-can.helper";
import RoleCan from "@/shared/components/RoleCan.vue";
import Model from "@/shared/classes/model";
import IModelResponse from "@/shared/interfaces/modules/model-response.interface";
import DataTableAction from "@/shared/classes/components/data-table/data-table-action";

import User from '@/shared/modules/user/user.model'
import { AuthGetters } from '@/shared/store/auth/auth.getters'
import SignHandbookButton from "@/company/components/hse-handbook/SignHandbookButton.vue";

@Component({
  methods: { __ },
  components: { DataTable, RoleCan, SignHandbookButton },
})
export default class AssignedHseRoutines extends Vue {
  RoleLevel = RoleLevel
  table: DataTableBase | null = null

  created() {
    this.table = new DataTableBase()
      .setModel(AssignedHseRoutinesModel)
      .setEndpoint(`${getNodeApiUrlByVersion()}/assigned-hse-routines`)
      .setGroupBy('hseRoutineCategoryName')
      .setGroupDesc(false)
      .setPaginationNotVisible(true)
      .setMultipleCheckbox(roleCan([RoleLevel.manager, RoleLevel.leader, RoleLevel.superadmin]))
      .setDoubleClick(this.navigateToEdit)
      .setGroupSelect(roleCan([RoleLevel.manager, RoleLevel.leader, RoleLevel.superadmin]))
      .setHeaders([
        new DataTableHeader()
          .setKey('name')
          .setSortable(false)
          .setText(__('company.views.hse-routines.assigned.table.columns.name')),
        new DataTableHeader()
          .setKey('responsibleUserName')
          .setSortable(false)
          .setText(__('company.views.hse-routines.assigned.table.columns.responsible')),
      ])
      .setSelectActions([
        new SelectAction()
          .setComponent(SelectedRoutineModify)
          .setEnabled(true)
          .setMeta({
            onSubmit: (data: any) => {
              const ids = this.table?.selected.map(el => el.uuid)
              this.updateMultiple({ ...data, uuids: ids })
            },
            onDelete: () => this.deleteMultiple((this.table?.selected || []) as AssignedHseRoutinesModel[]),
            revisedByPersonUuid: this.user.uuid
          })
      ])
      .setActions([
        new EditAction()
          .setAllowedForRole([RoleLevel.manager, RoleLevel.leader, RoleLevel.superadmin])
          .setAction(this.navigateToEdit),
        new DeleteAction()
          .setAllowedForRole([RoleLevel.manager, RoleLevel.leader, RoleLevel.superadmin])
          .setAction(this.delete),
        new DataTableAction()
          .setIcon('mdi-eye')
          .setAllowedForRole([RoleLevel.employee])
          .setAction((item: Model<IModelResponse>) => {
            this.$router.push({ name: CompanyRoutes.assignedRoutineView, params: { uuid: item.uuid } })
          })
      ])

    this.table.classes.tableCard = 'elevation-0'
    this.table.noPadding = true
  }

  navigateToCreate(): void {
    this.$router.push({ name: CompanyRoutes.assignedRoutineCreate })
  }

  navigateToEdit(item: AssignedHseRoutinesModel): void {
    if (roleCan([RoleLevel.manager, RoleLevel.leader, RoleLevel.superadmin])) {
      this.$router.push({ name: CompanyRoutes.assignedRoutineEdit, params: { uuid: item.uuid } })
    } else {
      this.$router.push({ name: CompanyRoutes.assignedRoutineView, params: { uuid: item.uuid } })
    }
  }

  delete(item: AssignedHseRoutinesModel): void {
    this.$store.dispatch(GlobalActions.showDialog, {
      show: true,
      component: AreYouSureDialog,
      meta: {
        title: __('company.views.hse-routines.assigned.table.actions.delete.title'),
        text: __('company.views.hse-routines.assigned.table.actions.delete.text', { name: item.name }),
        onYes: () => AssignedHseRoutinesService.destroy(item.uuid)
          .then(() => {
            this.table?.removeItem(item)
            this.$store.dispatch(GlobalActions.showSnackBar, {
              type: SnackBarTypes.success,
              message: __('company.views.hse-routines.assigned.table.actions.delete.on-success')
            })
          })
      }
    })
  }

  deleteMultiple(items: AssignedHseRoutinesModel[]): void {
    this.$store.dispatch(GlobalActions.showDialog, {
      show: true,
      component: AreYouSureDialog,
      meta: {
        title: __('company.views.hse-routines.assigned.table.actions.delete-multiple.title'),
        text: __('company.views.hse-routines.assigned.table.actions.delete-multiple.text', { name: '' }),
        onYes: () => AssignedHseRoutinesService.destroyMultiple(items.map(el => el.uuid))
          .then(() => {
            items.forEach(el => this.table?.removeItem(el))
            this.$store.dispatch(GlobalActions.showSnackBar, {
              type: SnackBarTypes.success,
              message: __('company.views.hse-routines.assigned.table.actions.delete-multiple.on-success')
            })
          })
      }
    })
  }

  updateMultiple(data: any): void {
    AssignedHseRoutinesService.patchMultiple(data)
      .then(() => {
        this.$store.dispatch(GlobalActions.showSnackBar, {
          type: SnackBarTypes.success,
          message: __("company.views.hse-routines.assigned.table.actions.update.on-success"),
        });
        this.tableRef?.refresh();
      });
  }

  get user(): User {
    return this.$store.getters[AuthGetters.getUser];
  }

  get company(): Company {
    return this.$store.getters[CompanyGetters.getCompany]
  }

  get tableRef(): any {
    return this.$refs.table
  }
}
