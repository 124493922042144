









import { Component, Prop, Vue } from "vue-property-decorator";
import IModelResponse from "@/shared/interfaces/modules/model-response.interface";
import Model from "@/shared/classes/model";
import __ from "@/shared/helpers/__";
import { GlobalActions } from "@/shared/store/global/global.actions";
import AreYouSureDialog from "@/shared/components/dialogs/AreYouSureDialog.vue";
import FormBase from "@/shared/classes/form/form-base";
import Form from "@/shared/components/form/Form.vue";
import { Positions } from "@/shared/interfaces/classes/form.interfaces";
import Company from "@/shared/modules/company/company.model";
import { CompanyGetters } from "@/shared/store/company/company.getters";
import SearchableField from "@/shared/classes/form/fields/Select/searchable-field";

@Component({
  components: { Form },
  methods: { __ },
})
export default class SelectedRoutineModify extends Vue {
  @Prop() selected!: Array<Model<IModelResponse>>
  @Prop() meta!: any
  form!: FormBase
  showForm: boolean = true

  created() {
    this.form = new FormBase()
      .setInline(true)
      .setFields([
        new SearchableField()
          .setKey('responsibleUserUuid')
          .setDense(true)
          .isRequired()
          .setHideDetails(true)
          .loadItems({
            endpoint: `company/${ this.company.slug }/employees`,
            value: 'uuid',
            title: 'full_name',
            perPage: 20,
          })
          .setTitle(__('company.components.hse-routines.selected-assign.form.responsible')),
      ])
      .setInjectValues({
        revisedByPersonUuid: this.meta.revisedByPersonUuid
      })
      .setBeforeSubmit(this.beforeSubmit)
      .setOnSuccess(this.meta.onSubmit)
      .setOnError(() => {
        this.showForm = false

        this.$nextTick(() => {
          this.showForm = true
        })
      })
      .setSubmit({
        color: 'primary',
        position: Positions.right,
        class: 'ml-2',
        text: __('general.modify'),
      })
  }

  get company(): Company {
    return this.$store.getters[CompanyGetters.getCompany]
  }

  beforeSubmit(): Promise<void> {
    return new Promise((resolve) => {
      this.$store.dispatch(GlobalActions.showDialog, {
        show: true,
        component: AreYouSureDialog,
        meta: {
          title: __('company.views.risk-assessments.assigned.table.actions.update.title'),
          text: __('company.views.hse-routines.assigned.table.actions.update.text'),
          onYes: () => resolve(),
        },
      })
    })
  }
}
